let connectionEstablished = false;
let siteUrl;
let browserName = getBrowserName();

/**
 * Firefox doesn't load the content.js from the extension before this file run, like Chrome does!
 * Maybe convert to switch case later..
 */
let waitToConnect = browserName === 'Firefox' ? 500 : 0;

window.onload = function () {
    iframe = document.getElementById("visualIframe");
    container = document.getElementById("visualIframeContainer");
    issuesLayer = document.getElementById("issuesLayer");
    site_domain = 'https://' + new URL(iframe.src).hostname;

    if (!connectionEstablished) {
        setTimeout(() => {
            connectToIframe();
        }, waitToConnect)
    }

    // watch connection state if connection is not established after 7.5 seconds emit wp_error
    setTimeout(() => {
        if (!connectionEstablished) {
            window.livewire.emit("updateState", {'state': "wp_error"});
        }
    }, 7500);
};

document.addEventListener("keydown", (e) => {
    if (e.code.toLowerCase() === "keyk" && e.altKey && e.shiftKey) {
        window.livewire.emit('toggleIssuesLayer');

    }
});

window.livewire.on("AnnotationOn", () => {
    iframe.contentWindow.postMessage(
        {
            function: "annotate",
        },
        site_domain
    );
    window.addEventListener("message", getElement, true);
});

window.livewire.on("AnnotationToggled", (data) => {
    if (data.val) {
        container.tabIndex = 0
        iframe.tabIndex = -1
    } else {
        container.tabIndex = -1
        iframe.tabIndex = 0
    }
});

window.livewire.on("updateState", (data) => {
    if(data.state !== "ready") {
        container.tabIndex = -1
        iframe.tabIndex = -1
    } else {
        container.tabIndex = 0
        iframe.tabIndex = -1
    }
});

function getElement(event) {
    if (event.origin == site_domain) {
        if (event.data.response == 'annotate') {
            window.removeEventListener("message", getElement, true);
            livewire.emit("elementClicked", event.data);
        }
    }
}

window.livewire.on("returnFocusTo", (id) => {
    let element = document.getElementById(id);
    if (element) {
        // wait for the element to be visible
        setTimeout(() => {
            element.focus();
        }, 300);
    }
});

window.livewire.on("sendCloseFullIssueEventToIframe", (data) => {
    iframe.contentWindow.postMessage(
        {
            function: "fullIssueClosed",
            returnFocus: data.returnFocus,
            returnFocusTo: data.issueId
        },
        site_domain
    );
});

window.livewire.on("sendOpenIssueEventToIframe", (data) => {
    iframe.contentWindow.postMessage(
        {
            function: "fullIssueOpened",
            issueSelector : data.issueSelector,
        },
        site_domain
    );
});

window.livewire.on("issuesLayerToggled", (data) => {
    iframe.contentWindow.postMessage(
        {
            function: "issuesLayerToggled",
            value: data.val
        },
        site_domain
    );
});


function handleURLChange() {
    let urlChanged = false;
    window.addEventListener(
        "message",
        (handle_input = (event) => {
            if (event.origin !== site_domain) {
                return; // Skip message in this event listener
            }
            if (event.data.msg == "urlChanged" && siteUrl != event.data.url) {
                urlChanged = true;
                siteUrl = event.data.url;
                window.livewire.emit("urlChanged", event.data.url);
            }
        }),
        false
    );
    setTimeout(() => {
        if (!urlChanged) {
            window.livewire.emit("updateState", {'state': "error", 'reason': 'site did not load correctly'});
        }
    }, 4000);
}

function connectToIframe() {
    iframe.contentWindow.postMessage(
        {
            function: "handShake",
        },
        site_domain
    );
    const initConnection = (event) => {
        if (event.origin == site_domain) {
            if (event.data.msg == "ACK") {
                connectionEstablished = true;
                window.removeEventListener("message", initConnection, true);
                if (connectionEstablished) {
                    window.livewire.emit("toggleIssuesLayer")
                    openFullIssueListener();
                    handleURLChange();
                    getReadyToTakeFocusBack();
                    window.livewire.emit("updateState", {'state': "ready"});
                }
            }
        }
    };
    window.addEventListener("message", initConnection, true);
}

function openFullIssueListener() {
    window.addEventListener("message", openFullIssue, true);
    function openFullIssue(e) {
        if (e.origin !== site_domain) return;
        if (e.data.msg !== 'openFullIssue') return;
        window.livewire.emit("openFullIssue", {issueId: e.data.issueId, fromSummary: e.data.fromSummary});
    }
}

function getReadyToTakeFocusBack() {
    window.addEventListener("message", takeFocus, true);
    function takeFocus(e) {
        if (e.origin !== site_domain) return;
        if (e.data.msg !== 'takeFocus') return;
        e.preventDefault();
        document.getElementById('visualIframeContainer').focus();
    }
}


// Send focus back to iframe on sort by severity key tab
document.body.addEventListener('keydown', maybeFocusIframe);
function maybeFocusIframe(e) {
    if(e.code !== 'Tab') return;
    if(e.shiftKey === true) return;
    if(e.target) {
        let elementId = e.target.id;
        if(elementId !== 'visualIframeContainer') return;
        e.preventDefault();
        iframe.contentWindow.postMessage( { function: "takeFocusBack" }, site_domain );
    }
}

